import Swal from "sweetalert2";

/**
 *  This function is called when the user log out our changes his subscription
 */


export function reloadWindow(timeout = 100){
    setTimeout(_ => window.location.reload(), timeout)
}

function isLocalhost(){
    return Boolean(
        window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
}

export async function UserLogoutTrace(subGuid) {
    //Send logout request to the API
    await fetch(`${BaseUrl()}/UserLoggedOut`, {
        headers: {
            "Subscription-Guid": subGuid,
            Authorization: localStorage.token
        }
    });
}

export function UserLogOut(subGuid) {
    if(subGuid){
        UserLogoutTrace(subGuid);
    }    

    //Clear localstorage
    localStorage.clear();

    //Redirect to the login page
    //window.location.href = 'https://auth.inabex.net/loginv2/login?backurl=livedatacloud.inabex.me/token&logout=true';
    //window.location.href = 'https://testauth.inabex.net/loginv2/login?backurl=testlivedatacould.inabex.me/token&logout=true';
    //window.location.href = 'https://testauth.inabex.net/loginv2/login?backurl=localhost:3000/token&logout=true';
    //window.location.href = "http://192.168.16.11/doubleauth/loginv2/login?backurl=localhost:3000/token&logout=true";
    //window.location.href = 'http://192.168.16.11/doubleauth/loginv2/login?backurl=192.168.16.11/livedatacloud/token&logout=true';
    //window.location.href = 'https://auth.inabex.net/loginv2/login?backurl=inabex.live/token&logout=true';
    if(isLocalhost()){
        window.location.replace('https://auth.inabex.net/loginv2/login?backurl=localhost:3000/token&logout=true');
    }
    else{
        window.location.href = 'https://auth.inabex.net/loginv2/login?backurl=inabex.live/token&logout=true';
    }
    
}

export function UserLogIn(subGuid) {
    //Send login request to the API
    fetch(`${BaseUrl()}/UserLoggedIn`, {
        headers: {
            "Subscription-Guid": subGuid,
            Authorization: localStorage.token
        }
    });
}

export function errorAlert(text) {
    return Swal.fire({
        title: "Error!",
        text: text,
        type: "error",
        confirmButtonText: "OK"
    });
}

export function successAlert(text) {
    return Swal.fire({
        title: "Opération réussie",
        text: text,
        type: "success",
        confirmButtonText: "OK"
    });
}

export function BaseUrl() {
    // Local server
    return isLocalhost() ? 'http://localhost:8088/api' : 'https://ws.inabex.live/api';
    //return 'http://localhost/LivedatacloudAPI/api';
    // Development server
    //return 'https://testlivedatacould.inabex.ws/api';
    // Production server
    //return 'https://ws.inabex.live/api';
}

export function localUrl() {
    // local server
    return '/';

    // 11 server
    //return '/livedatacloud/';
}

export function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}
