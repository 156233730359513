import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserLogOut, BaseUrl, errorAlert } from "../GlobalFunctions";
import SubscriptionInfo from "./SubscriptionInfoModel";
import Links from "./HeaderLinks";
import SubscriptionSelectinModel from './SubscriptionSelectionModel';

const Header = props => {
  const [data, setData] = useState({
    FullName: "N/A",
    Email: "N/A",
    Role: "",
    Image: ""
  });
  
  const [customerInfo, setCustomerInfo] = useState({
    customerName: "Customers",
    subscription: {
      GUID: "N/A",
      Label: "Subscriptions",
      EndDate: new Date(),
      Permissions: [],
      Settings: [],
      UseBatch: false
    },
    isAdmin: false,
    isPharmaceutical: false
  });
  
  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    document.body.classList.add("m-page--loading");
  } else {
    document.body.classList.remove("m-page--loading");
  }

  useEffect(() => {
    async function fetchUserInfo() {
      setIsLoading(true);
      let response = await fetch(`${BaseUrl()}/UserInfo`, {
        headers: {
          Authorization: localStorage.token
        }
      });

      if (response.status === 200) {
        let result = await response.json();
        setData(result);
      } else if (response.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(() => UserLogOut(customerInfo.subscription.GUID));
      } else {
        errorAlert("An error occured");
      }

      setIsLoading(false);
    }
    fetchUserInfo();
  }, []);
  const subscriptionGuidCallback = customerInfo =>{
    setCustomerInfo(customerInfo);
    props.subscriptionGuidCallback(customerInfo);
  }
  
  return (
    <>
      <header
        id="m_header"
        className="m-grid__item    m-header "
        m-minimize="minimize"
        m-minimize-mobile="minimize"
        m-minimize-offset="200"
        m-minimize-mobile-offset="200"
      >
        <div className="m-container m-container--fluid m-container--full-height">
          <div className="m-stack m-stack--ver m-stack--desktop  m-header__wrapper">
            <div className="m-stack__item m-brand m-brand--mobile">
              <div className="m-stack__item m-stack__item--middle m-brand__logo">
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                className="m-brand__logo-wrapper"
              >
                <img alt="" src="/assets/img/logo.png" style={{position: 'absolute', left: '25px',    top: '25px',  width: '100px'}}/>
              </Link>
              </div>
              <div className="m-stack m-stack--ver m-stack--general">
                <div className="m-stack__item m-stack__item--middle m-brand__tools">
                  <a
                    id="m_aside_header_menu_mobile_toggle"
                    href="#"
                    className="m-brand__icon m-brand__toggler"
                  >
                    <span></span>
                  </a>
                  <a
                    id="m_aside_header_topbar_mobile_toggle"
                    href="#"
                    className="m-brand__icon"
                  >
                    <i className="flaticon-more"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="m-stack__item m-stack__item--middle m-brand__logo"
              style={{ width: "50px" }}
            >
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                className="m-brand__logo-wrapper"
              >
                <img alt="" src="/assets/img/logo.png" style={{position: 'absolute', left: '25px',    top: '25px',     width: '100px'}} />
              </Link>
            </div>
            <Links customerInfo={customerInfo} isAdmin={props.isAdmin} />
            <div className="m-stack__item m-stack__item--right">
              <div
                id="m_header_topbar"
                className="m-topbar  m-stack m-stack--ver m-stack--general"
              >
                <div className="m-stack__item m-topbar__nav-wrapper">
                  <ul className="m-topbar__nav m-nav m-nav--inline">
                    <li
                      className="m-nav__item m-nav__item--danger m-dropdown m-dropdown--skin-light m-dropdown--large m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push m-dropdown--mobile-full-width m-dropdown--skin-light"
                      m-dropdown-toggle="click"
                    >
                      <a href="#" className="m-nav__link m-dropdown__toggle">
                        <span className="m-nav__link-badge m-badge m-badge--dot m-badge--info m--hide"></span>
                        <span className="m-nav__link-icon">
                          <span className="m-nav__link-icon-wrapper">
                            <i className="flaticon-share"></i>
                          </span>
                        </span>
                      </a>
                      <div className="m-dropdown__wrapper">
                        <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                        <div className="m-dropdown__inner">
                          <div className="m-dropdown__header m--align-center">
                            <span className="m-dropdown__header-title">
                              Quick Actions
                            </span>
                          </div>
                          <div className="m-dropdown__body m-dropdown__body--paddingless">
                            <div className="m-dropdown__content">
                              <div
                                className="m-scrollable"
                                data-scrollable="false"
                                data-height="380"
                                data-mobile-height="200"
                              >
                                <div className="m-nav-grid m-nav-grid--skin-light">
                                  <div className="m-nav-grid__row">
                                    <a
                                      href="#"
                                      className="m-nav-grid__item"
                                      data-toggle="modal"
                                      data-target="#m_modal_5"
                                    >
                                      <i className="m-nav-grid__icon flaticon-tabs"></i>
                                      <span className="m-nav-grid__text">
                                        Changement d'abonnement
                                      </span>
                                    </a>
                                  </div>

                                  <div className="m-nav-grid__row">
                                    <a
                                      href="#"
                                      className="m-nav-grid__item"
                                      data-toggle="modal"
                                      data-target="#m_modal_6"
                                    >
                                      <i className="m-nav-grid__icon flaticon-information"></i>
                                      <span className="m-nav-grid__text">
                                        Informations sur l'abonnement
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      className="m-nav__item m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                      m-dropdown-toggle="click"
                    >
                      <a href="#" className="m-nav__link m-dropdown__toggle">
                        <span className="m-topbar__username m--hidden-mobile">
                          {data.FullName}
                        </span>
                        <span className="m-topbar__userpic">
                          <img
                            src={data.UrlImage}
                            className="m--img-rounded m--marginless m--img-centered"
                            alt=""
                          />
                        </span>
                        <span className="m-nav__link-icon m-topbar__usericon  m--hide">
                          <span className="m-nav__link-icon-wrapper">
                            <i className="flaticon-user-ok"></i>
                          </span>
                        </span>
                      </a>
                      <div className="m-dropdown__wrapper">
                        <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                        <div className="m-dropdown__inner">
                          <div className="m-dropdown__header m--align-center">
                            <div className="m-card-user m-card-user--skin-light">
                              <div className="m-card-user__pic">
                                <img
                                  src={data.UrlImage}
                                  className="m--img-rounded m--marginless"
                                  alt=""
                                />
                              </div>
                              <div className="m-card-user__details">
                                <span className="m-card-user__name m--font-weight-500">
                                  {data.FullName}
                                </span>
                                <a
                                  href=""
                                  className="m-card-user__email m--font-weight-300 m-link"
                                >
                                  {data.Email}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="m-dropdown__body">
                            <div className="m-dropdown__content">
                              <ul className="m-nav m-nav--skin-light">
                                <li className="m-nav__separator m-nav__separator--fit"></li>
                                <li className="m-nav__item">
                                  <a
                                    href="#"
                                    className="btn m-btn--pill    btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                    onClick={() => UserLogOut(customerInfo.subscription.GUID)}
                                  >
                                    Logout
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <SubscriptionSelectinModel subscriptionGuidCallback={subscriptionGuidCallback}/>
      <SubscriptionInfo customerInfo={customerInfo} />
    </>
  );
};

export default Header;
