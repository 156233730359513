import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  UserLogOut,
  BaseUrl,
  errorAlert
} from "../../Components/GlobalFunctions";
//import { Link } from "react-router-dom";

const ClientDetailView = props => {
  let { Id } = useParams();
  const [data, setData] = useState({
    Code: "N/A",
    Reference: "N/A",
    Label: "N/A",
    Poses: [
      {
        Balance: "N/A",
        Code: "N/A",
        Label: "N/A",
        MaxBalance: "N/A"
      }
    ],
    HasPermission: true
  });

  const [isLoading, setIsLoading] = useState(false);
  if(isLoading){
    document.body.classList.add('m-page--loading')
  }
  else{
    document.body.classList.remove('m-page--loading')
  }

  

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      let response = await fetch(`${BaseUrl()}/ThirdPartyClient/${Id}`, {
        method: "get",
        headers: {
          "Subscription-Guid": props.subscriptionGuid,
          Authorization: localStorage.token
        }
      });

      if (response.status === 200) {
        let result = await response.json();
        setData(result);
      } else if (response.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(() => UserLogOut(props.subscriptionGuid));
      } else {
        errorAlert("An error occured");
      }
      setIsLoading(false);
    }
    fetchData();
  }, [props.subscriptionGuid]);

  const header = (
    <div className="m-subheader ">
      <div className="d-flex align-items-center">
        <div className="mr-auto">
          <h3 className="m-subheader__title m-subheader__title--separator">
          Detail Client
          </h3>
          <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li className="m-nav__item m-nav__item--home">
              <a href="#" className="m-nav__link m-nav__link--icon">
                <i className="m-nav__link-icon la la-home"></i>
              </a>
            </li>
            <li className="m-nav__separator">-</li>
            <li className="m-nav__item">
              <a href="" className="m-nav__link">
                <span className="m-nav__link-text">Client</span>
              </a>
            </li>
            <li className="m-nav__separator">-</li>
            <li className="m-nav__item">
              <a href="" className="m-nav__link">
                <span className="m-nav__link-text">Détail Client</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
  const body = (
    <div className="row">
      <div className="col-xl-6">
        <div className="m-portlet m-portlet--full-height ">
          <div className="m-portlet__head">
            <div className="m-portlet__head-caption">
              <div className="m-portlet__head-title">
                <h3 className="m-portlet__head-text">Détail Client</h3>
              </div>
            </div>
          </div>
          <div className="m-portlet__body">
            <div className="m-widget13">
              <div className="m-widget13__item">
                <span className="m-widget13__desc m--align-right" style={{ textAlign: "left", width: "10%" }}>Code</span>
                <span className="m-widget13__text m-widget13__text-bolder">
                  {data.Code}
                </span>
              </div>
              <div className="m-widget13__item">
                <span className="m-widget13__desc m--align-right" style={{ textAlign: "left", width: "10%" }}>Nom</span>
                <span className="m-widget13__text m-widget13__text-bolder">
                  {data.Label}
                </span>
              </div>
              <div className="m-widget13__item">
                <span className="m-widget13__desc m--align-right" style={{ textAlign: "left", width: "10%" }}>
                  Reference
                </span>
                <span className="m-widget13__text m-widget13__text-bolder">
                  {data.Reference}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6" style={{ textAlign: "left" }}>
        <div className="m-portlet m-portlet--full-height ">
          <div className="m-portlet__head">
            <div className="m-portlet__head-caption">
              <div className="m-portlet__head-title">
                <h3 className="m-portlet__head-text">Résumé</h3>
              </div>
            </div>
          </div>
          <div className="m-portlet__body">
            <div className="tab-content">
              <div className="tab-pane active" id="m_widget11_tab1_content">
                <div className="m-widget11"  data-height="300" data-mobile-height="300">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <td className="m-widget11__app">Point de vente</td>
                          <td className="m-widget11__total m--align-right">
                            Solde
                          </td>
                          <td className="m-widget11__total m--align-right">
                            Solde Max
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {data.Poses.map(item => (
                          <tr key={item.Code}>
                            <td>
                              <span className="m-widget11__title">
                                {item.Code} | {item.Label}
                              </span>
                            </td>
                            <td className="m--align-right m--font-brand">
                                {item.Balance != null ?new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "DZD"
                                }).format(item.Balance) : ""}
                            </td>
                            <td className="m--align-right m--font-brand">
                            {item.MaxBalance != null ?new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "DZD"
                                }).format(item.MaxBalance) : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
  return (
    <div>
      {header}
      {!data.HasPermission && 
            <div className="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show" role="alert">
            <div className="m-alert__icon">
              <i className="flaticon-exclamation-1"></i>
              <span></span>
            </div>
            <div className="m-alert__text">
                <strong>Permissions rejetées!</strong>  Vous n'avez pas les permissions pour consulter cette page.
            </div>		  	
          </div>
          }
      {body}
    </div>
  );
};

export default ClientDetailView;
