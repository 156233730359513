import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty, BaseUrl } from "../../Components/GlobalFunctions";
import SimpleViewResource from "./SimpleViewResources";
import DataGrid, {
  Column,
  Paging,
  Pager,
  SearchPanel
} from "devextreme-react/data-grid";
import "whatwg-fetch";
import { Link } from "react-router-dom";
import { localUrl } from '../GlobalFunctions';

const pageSizes = [10, 25, 50, 100];

const cellCurrencyRender = props => {
  if(props.value != null)
    return new Intl.NumberFormat("de-DE", {style: "currency", currency: "DZD"}).format(props.value);
  return "";
}


const Clients = props => {
  const [data, setData] = useState({
    redirect: false,
    clientId: 0
  });

  const onSelectionChanged = ({ selectedRowsData }) => {
    setData({
      redirect: true,
      clientId: selectedRowsData[0].ID
    });
  };
  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Liste des Clients
            </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="m-nav__link m-nav__link--icon"
                >
                  <i className="m-nav__link-icon la la-home"></i>
                </Link>
              </li>
              <li className="m-nav__separator">-</li>
              <li className="m-nav__item">
                <a href="" className="m-nav__link">
                  <span className="m-nav__link-text">Liste des Clients</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="m-content">
        <SimpleViewResource
          subscriptionGuid={props.subscriptionGuid}
          link={`${BaseUrl()}/ThirdPartyClients`}
          render={data => {
            if (!!data && !isEmpty(data)) {
              if (!data.items.HasPermission)
                return (
                  <div
                    className="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <div className="m-alert__icon">
                      <i className="flaticon-exclamation-1"></i>
                      <span></span>
                    </div>
                    <div className="m-alert__text">
                      <strong>Permissions rejetées!</strong> Vous n'avez pas les
                      permissions pour consulter cette page.
                    </div>
                  </div>
                );
              else {
                return <DataGrid
                  dataSource={data.items.Data}
                  selection={{ mode: "single" }}
                  showBorders={true}
                  hoverStateEnabled={true}
                  onSelectionChanged={onSelectionChanged}
                  keyExpr={"ID"}
                >
                  <SearchPanel visible={true} />
                  <Column dataField={"Code"} caption={"Code"}/>
                  <Column dataField={"Label"} caption={"Raison Sociale"}/>
                  <Column dataField={"Reference"} caption={"Référence"}/>
                  <Column dataField={"Balance"} caption={"Solde"} cellRender={cellCurrencyRender} />

                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={pageSizes}
                  />
                </DataGrid>;
              }
            }
            return <h1>No data</h1>;
          }}
        />
        {data.redirect && (
          <Redirect
            push
            to={{
              pathname: `${localUrl()}ClientDetail/${data.clientId}`
            }}
          />
        )}
      </div>
    </div>
  );
};
export default Clients;