import React from 'react';

const SubscriptionInfo = props => (<div
    className="modal fade"
    id="m_modal_6"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-sm" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Informations sur l'abonnement
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Label: </h5> {props.customerInfo.subscription.Label}
          <br />
          <h5>Experation Date: </h5> {new Date(props.customerInfo.subscription.EndDate).toLocaleString()}
          <br />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div> );

export default SubscriptionInfo