import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  UserLogOut,
  BaseUrl,
  errorAlert
} from "../../Components/GlobalFunctions";
import { Link } from "react-router-dom";
import { Card, Accordion, Button } from "react-bootstrap";
import { formatDateFr } from '../../Utils/DateUtils'

const ProductDetailView = props => {
  let { Id } = useParams();

  const [data, setData] = useState({
    Item: {
      Id: 0,
      Code: "N/A",
      Reference: "N/A",
      Label: "N/A",
      Dose: null,
      DCI: null,
      Presentation: null,
      TariffType: null
    },
    Poses: [
      {
        Warehouses: [
          {
            Batches: [
              {
                Quantity: 0.0,
                Code: "N/A",
                ExpirationDate: new Date(),
                PurchasePrice: 0.0,
                SalePrice: 0.0,
                WholeSalePrice: 0.0
              }
            ],
            Code: "N/A",
            Label: "N/A",
            Quantity: 0,
            UseBatches: true
          }
        ],
        Quantity: 0,
        POSCode: "N/A",
        POSLabel: "N/A",
        LicenseID: "N/A",
        LastUpdateDate: new Date(),
        ReservedQuantity: 0,
        PMP: 0,
        ReservedQuantity: 0
      }
    ],
    PosesPrices: [
      {
        POSCode: "N/A",
        POSLabel: "N/A",
        Platform: 0,
        PricesDetail: [
          {
            Tariff: "N/A",
            Label: "N/A",
            Price: 0,
            BatchCode: "N/A",
            BatchLabel: "N/A",
            ExpirationDate: null,
            Colissage: [{
              Label: "N/A",
              Prices: [{Tariff: "N/A", ColissagePrice: 0}]
            }],
            QuantityPrices:[{
              Tariff: "N/A",
              Prices: [{
                Range: "N/A",
                Price: 0
              }]
            }]
          }
        ]
      }
    ]
  });

  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    document.body.classList.add("m-page--loading");
  } else {
    document.body.classList.remove("m-page--loading");
  }

  useEffect(() => {
    let link = !!props.UseBatches 
      ? `${BaseUrl()}/itemUsebatch/${Id}`
      : `${BaseUrl()}/item/${Id}`;

    async function fetchData() {
      setIsLoading(true);
      let response = await fetch(link, {
        method: "get",
        headers: {
          "Subscription-Guid": props.subscriptionGuid,
          Authorization: localStorage.token
        }
      });
      if (response.status === 200) {
        let result = await response.json();
        setData(result);
      } else if (response.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(UserLogOut);
      } else {
        errorAlert("An error occured");
      }
      setIsLoading(false);
    }
    fetchData();
  }, [props.subscriptionGuid]);


  const header = (
    <>
      
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Détail Produit
          </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="m-nav__link m-nav__link--icon"
                >
                  <i className="m-nav__link-icon la la-home"></i>
                </Link>
              </li>
              <li className="m-nav__separator">-</li>
              <li className="m-nav__item">
                <a href="" className="m-nav__link">
                  <span className="m-nav__link-text">Produit</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>

  );

  const GetPosQuantity = posItem => {
    if(props.UseBatches != undefined && props.UseBatches == true){
      let quantity = 0;
      posItem.Warehouses.forEach(element => {
        element.Batches.forEach(item => {
          quantity = quantity + item.Quantity;
        });
      });
      return quantity;
    }
    return posItem.Quantity;

  };

  const GetWarehouseQuantity = warehouse => {
    if (warehouse.Quantity != null)
      return warehouse.Quantity;

    return warehouse.Batches.reduce((acc, curr) => acc += curr.Quantity, 0);
  }
  const posQuantities = (
    <div className="col-xl-6" style={{ textAlign: "left" }}>
      <div className="m-portlet m-portlet--full-height ">
        <div className="m-portlet__head">
          <div className="m-portlet__head-caption">
            <div className="m-portlet__head-title">
              <h3 className="m-portlet__head-text">Résumé</h3>
            </div>
          </div>
        </div>
        <div className="m-portlet__body">
          <div className="tab-content">
            <div className="tab-pane active" id="m_widget11_tab1_content">
              <div className="m-widget11">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <td className="m-widget11__app">Point de vente</td>
                        <td className="m-widget11__total m--align-right">
                          Quantité
                        </td>
                        <td className="m-widget11__total m--align-right">
                          Quantité réservée
                        </td>
                        <td className="m-widget11__total m--align-right">
                          Dernier prix d'achat
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.Poses.map((posItem, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className="m-widget11__title"
                              className={
                                new Date(posItem.LastUpdateDate).getTime() <
                                  new Date().getTime() - props.quantum * 60 * 1000
                                  ? "m--font-danger"
                                  : "m--font-success"
                              }
                            >
                              {posItem.POSCode} | {posItem.Label}
                            </span>
                          </td>
                          <td className="m--align-right m--font-brand">
                            {new Intl.NumberFormat().format(
                              GetPosQuantity(posItem)
                            )}
                          </td>
                          <td className="m--align-right m--font-brand">
                            {posItem.ReservedQuantity != null ? new Intl.NumberFormat().format(
                              posItem.ReservedQuantity
                            ) : ""}
                          </td>
                          <td className="m--align-right m--font-brand">
                            {posItem.LastPurchasePrice != null ? new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "DZD"
                            }).format(posItem.LastPurchasePrice) : ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const itemDetail = (
    <>
    <div className="row">
      <div className="col-xl-6">
        <div className="m-portlet m-portlet--full-height ">
          <div className="m-portlet__head">
            <div className="m-portlet__head-caption">
              <div className="m-portlet__head-title">
                <h3 className="m-portlet__head-text">Detail Produit</h3>
              </div>
            </div>
          </div>
          <div className="m-portlet__body">
            <div className="m-widget13">
              <div className="m-widget13__item">
                <span
                  className="m-widget13__desc m--align-right"
                  style={{ textAlign: "left", width: "10%" }}
                >
                  Code
                </span>
                <span
                  className="m-widget13__text m-widget13__text-bolder"
                >
                  {data.Item.Code}
                </span>
              </div>
              <div className="m-widget13__item">
                <span
                  className="m-widget13__desc m--align-right"
                  style={{ textAlign: "left", width: "10%" }}
                >
                  Référence
                </span>
                <span
                  className="m-widget13__text m-widget13__text-bolder"
                >
                  {data.Item.Reference}
                </span>
              </div>
              <div className="m-widget13__item">
                <span
                  className="m-widget13__desc m--align-right"
                  style={{ textAlign: "left", width: "10%" }}
                >
                  Nom
                </span>
                <span
                  className="m-widget13__text m-widget13__text-bolder"
                >
                  {data.Item.Label}
                </span>
              </div>
              
              { /* data.Item.Batches && data.Item.Batches.length > 0 && (
                <div className="m-widget13__item">
                <span
                  className="m-widget13__desc m--align-right"
                  style={{ textAlign: "left", width: "10%" }}
                >
                  Lot
                </span>
                <span
                  className="m-widget13__text m-widget13__text-bolder"
                >
                  {data.Item.Batches}
                </span>
              </div>
              )*/
              }
              

              {data.Item.DCI != null &&
                <div className="m-widget13__item">
                  <span
                    className="m-widget13__desc m--align-right"
                    style={{ textAlign: "left", width: "10%" }}
                  >
                    DCI
                </span>
                  <span
                    className="m-widget13__text m-widget13__text-bolder"
                  >
                    {data.Item.DCI}
                  </span>
                </div>
              }
              {data.Item.Dose != null &&
                <div className="m-widget13__item">
                  <span
                    className="m-widget13__desc m--align-right"
                    style={{ textAlign: "left", width: "10%" }}
                  >
                    Dose
                </span>
                  <span
                    className="m-widget13__text m-widget13__text-bolder"
                  >
                    {data.Item.Dose}
                  </span>
                </div>
              }
              {data.Item.Presentation != null &&
                <div className="m-widget13__item">
                  <span
                    className="m-widget13__desc m--align-right"
                    style={{ textAlign: "left", width: "10%" }}
                  >
                    Presentation
                </span>
                  <span
                    className="m-widget13__text m-widget13__text-bolder"
                  >
                    {data.Item.Presentation}
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {posQuantities}
    </div>
    </>
  );

  const posPrices = () => {
    let poses = data.PosesPrices.map((pos, index) => (<Card style={{ width: '100%' }} key={index}>
      <Card.Header as="h5">{pos.POSLabel}  [{pos.POSCode}]</Card.Header>
      <Card.Body className="row justify-content-center">
        {
          pos.PricesDetail.map((x, i) => {
            if(x.QuantityPrices != null && x.QuantityPrices.length > 0){
              return x.QuantityPrices.map((qp, coliIndex) => (
                <Card style={{ width: '25rem', margin: '20px' }} key={coliIndex} className="col-md-3">
                  <Card.Body>
                    <Card.Title>Tarif: {qp.Tariff}</Card.Title>
                    <div className="row justify-content-center">
                      {qp.Prices.map((qpPrice, qpPriceIndex) => (
                        <p key={qpPriceIndex} className="col-sm-10">
                          <strong >{qpPrice.Range}</strong> = {new Intl.NumberFormat("de-DE", { style: "currency", currency: "DZD" }).format(qpPrice.Price)} 
                        </p>
                      ))}
                    </div>
                  </Card.Body>
                </Card>
                
              ))
            }
            if(x.Colissage != null && x.Colissage.length > 0){
              return x.Colissage.map((coli, coliIndex) => (
                <Card style={{ width: '25rem', margin: '20px' }} key={coliIndex} className="col-md-3">
                  <Card.Body>
                    <Card.Title>Colisage: {coli.Label}</Card.Title>
                    <div className="row justify-content-center">
                      {coli.Prices.map((coliPrice, coliPriceIndex) => (
                        <p key={coliPriceIndex} className="col-sm-10">
                          <strong>{coliPrice.Tariff}</strong>:  {new Intl.NumberFormat("de-DE", { style: "currency", currency: "DZD" }).format(coliPrice.ColissagePrice)} 
                        </p>
                      ))}
                    </div>
                  </Card.Body>
                </Card>
                
              ))
            }
            else{
              return (
                <Card style={{ width: '25rem', margin: '20px' }} key={i} className="col-md-3">
                  <Card.Body>
                    <Card.Title>{x.Tariff}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{new Intl.NumberFormat("de-DE", { style: "currency", currency: "DZD" }).format(x.Price)}</Card.Subtitle>
                    <Card.Text>
                      {x.Label != null ? x.Label : ""}
                      {x.BatchCode != null ? `Lot: ${x.BatchCode}` : ""} <br />
                      {x.ExpirationDate != null ? `Expiration: ${new Date(x.ExpirationDate).getDay()}/${new Date(x.ExpirationDate).getMonth()}/${new Date(x.ExpirationDate).getFullYear()}` : ""}
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            }

          })
        }
      </Card.Body>
    </Card>));


    return (
      <div className="row">
        {poses}
      </div>);
  }

  const warehousesBatches = () => {
    //if (props.UseBatch) {
    return (
      <div className="row">
        {data.Poses.map((posItem, index) => (
          <div className="col-xl-6" style={{ textAlign: "left" }} key={index}>
            <div className="m-portlet m-portlet--full-height">
              <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                  <div className="m-portlet__head-title">
                    <h3 className="m-portlet__head-text">
                      {posItem.POSCode} | {posItem.Label}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="m-portlet__body">
                <div
                  className="m-widget4"
                  data-scrollable="true"
                  data-height="300"
                  data-mobile-height="300"
                >
                  <div
                    className="m-accordion m-accordion--default"
                    id="m_accordion_1"
                    role="tablist"
                  >
                    {posItem.Warehouses && posItem.Warehouses.map((warehouseItem, subIndex) =>
                      (
                        <div className="m-accordion__item" key={subIndex}>
                          <div
                            className="m-accordion__item-head collapsed"
                            role="tab"
                            id="m_accordion_1_item_1_head"
                            data-toggle="collapse"
                            href={
                              "#" +
                              "m_accordion" +
                              subIndex +
                              "_item_" +
                              subIndex +
                              "_body"
                            }
                            aria-expanded="false"
                          >
                            <span className="m-accordion__item-icon">
                              <i className="fa flaticon-home"></i>
                            </span>
                            <span className="m-accordion__item-title">
                              <h5> {warehouseItem.Label} | {warehouseItem.Code}</h5>  Quantité Totale :  {new Intl.NumberFormat().format(GetWarehouseQuantity(warehouseItem))}
                            </span>
                            <span className="m-accordion__item-mode"></span>
                          </div>


                          <div
                            className="m-accordion__item-body collapse"
                            id={
                              "m_accordion" +
                              subIndex +
                              "_item_" +
                              subIndex +
                              "_body"
                            }
                            className=" "
                            role="tabpanel"
                            aria-labelledby="m_accordion_1_item_1_head"
                            data-parent="#m_accordion_1"
                          >
                            <div className="m-accordion__item-content">


                              {warehouseItem.Batches != undefined && warehouseItem.Batches.length > 0 && (
                                <table className="table table-sm m-table m-table--head-bg-brand">
                                  <thead className="thead-inverse">
                                    <tr>
                                      <th>#</th>
                                      <th>N° Lot</th>
                                      <th>Date d'expiration</th>
                                      <th>Prix d'achat</th>
                                      <th>Prix de vente</th>
                                      <th>Prix de vente gros</th>
                                      <th>Quantité</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {warehouseItem.Batches.map(
                                      (batchItem, batchIndex) => (
                                        <tr key={batchIndex}>
                                          <th scope="row">{batchIndex + 1}</th>
                                          <td>{batchItem.BatchNum}</td>
                                          <td>
                                            {formatDateFr(batchItem.ExpirationDate)}
                                          </td>
                                          <td>{batchItem.PurchasePrice != null ? new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(batchItem.PurchasePrice) : ""}</td>
                                          <td>{batchItem.SalePrice != null ? new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(batchItem.SalePrice) : ""}</td>
                                          <td>{batchItem.WholeSalePrice != null ? new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "DZD"
                                          }).format(batchItem.WholeSalePrice) : ""}</td>
                                          <td>
                                            {new Intl.NumberFormat().format(
                                              batchItem.Quantity
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
    // } else {
    //   return <> </>;
    // }
  };

  return (
    <>
      {header}
      {itemDetail}
      {warehousesBatches()}
      <hr />
      <h4 style={{ textAlign: "center" }}> Liste des prix pour chaque POS</h4>
      {posPrices()}
    </>
  );
};

export default ProductDetailView;
