import React, { useState } from "react";

import "./App.css";
import Header from "./Components/Header/Header.js";
import Home from "./Components/Home";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import AdvancedProductView from "./Components/AdvancedView/AdvancedProductView";
import AdvancedClientView from "./Components/AdvancedView/AdvancedClientView";
import AdvancedProviderView from "./Components/AdvancedView/AdvancedProviderView";

import ProductView from "./Components/SimpleView/ProductView";
import ClientView from "./Components/SimpleView/ClientView";
import ProviderView from "./Components/SimpleView/ProviderView";

import ProductDetailView from "./Components/DetailView/ProductDetailView";
import ProviderDetailView from "./Components/DetailView/ProviderDetailView";
import ClientDetailView from "./Components/DetailView/ClientDetailView ";

import ResetDatabase from "./Components/Administration/ResetDatabase";
import ConnectionLogHistory from "./Components/Administration/ConnectionLogHistory";
import PosConfiguration from "./Components/Administration/PosConfiguration";
import WarehouseConfiguration from "./Components/Administration/WarehouseConfiguration";

import Token from "./Components/Token";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SubscriptionSelectinModel from "./Components/Header/SubscriptionSelectionModel";
import { UserLogoutTrace, UserLogIn } from "./Components/GlobalFunctions";
const App = (props) => {

  // localStorage.token = "Bearer xxx..."
  const token = localStorage.token;

  const [data, setData] = useState({
    customerName: "Customers",
    subscription: {
      GUID: "N/A",
      Label: "Subscriptions",
      EndDate: new Date(),
      UseBatch: false,
      SyncQuantum: 30,
      IsAdmin: false
    },
    isPharmaceutical: false
  });
  
  if (localStorage.currentCustomer !== undefined &&data.subscription.GUID === "N/A") {
    setData(JSON.parse(localStorage.currentCustomer));
  }

  const subscriptionCallback = customer => {
    if (data.subscription.GUID !== "N/A") {
      UserLogoutTrace(data.subscription.GUID);
    }

    setData(customer);
    localStorage.currentCustomer = JSON.stringify(customer);

    UserLogIn(customer.subscription.GUID);
  };

// if not authenticated => redirect to token component
  if(!token || token?.length < 20) {
    
    return (<Router>
                <Route path={`*`}>
                  <Token />
                </Route>
              </Router>
              )
  }

  return (
    <Router>
      <div className="App">
        <div className="m-grid m-grid--hor m-grid--root m-page">
          <Header
            subscriptionGuidCallback={subscriptionCallback}
            isAdmin={data.subscription.IsAdmin}
          />
          <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor-desktop m-grid--desktop m-body">
            <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-container--full-height">
              <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <Switch>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/ProductAdvanced`}
                  >
                    <AdvancedProductView
                      subscriptionGuid={data.subscription.GUID}
                      useBatch={data.subscription.UseBatch}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/ClientAdvanced`}
                  >
                    <AdvancedClientView
                      subscriptionGuid={data.subscription.GUID}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/AdvancedProvider`}
                  >
                    <AdvancedProviderView
                      subscriptionGuid={data.subscription.GUID}
                    />
                  </Route>
                  <Route exact path={`${process.env.PUBLIC_URL}/Products`}>
                    <ProductView
                      subscriptionGuid={data.subscription.GUID}
                      isPharmaceutical={data.isPharmaceutical}
                      UseBatch={data.subscription.UseBatch}
                    />
                  </Route>
                  <Route exact path={`${process.env.PUBLIC_URL}/Clients`}>
                    <ClientView subscriptionGuid={data.subscription.GUID} />
                  </Route>
                  <Route exact path={`${process.env.PUBLIC_URL}/Providers`}>
                    <ProviderView subscriptionGuid={data.subscription.GUID} />
                  </Route>
                  <Route exact path={`${process.env.PUBLIC_URL}/ResetDatabase`}>
                    <ResetDatabase subscriptionGuid={data.subscription.GUID} />
                  </Route>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/ConnectionsLogHistory`}
                  >
                    <ConnectionLogHistory
                      subscriptionGuid={data.subscription.GUID}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/ItemDetail/:Id`}
                  >
                    <ProductDetailView
                      UseBatches={data.subscription.UseBatch}
                      subscriptionGuid={data.subscription.GUID}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/ClientDetail/:Id`}
                  >
                    <ClientDetailView
                      subscriptionGuid={data.subscription.GUID}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/ProviderDetail/:Id`}
                  >
                    <ProviderDetailView
                      subscriptionGuid={data.subscription.GUID}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/PosConfiguration`}
                  >
                    <PosConfiguration
                      subscriptionGuid={data.subscription.GUID}
                    />
                  </Route>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/WarehouseConfiguration`}
                  >
                    <WarehouseConfiguration
                      subscriptionGuid={data.subscription.GUID}
                    />
                  </Route>
                  <Route exact path={`${process.env.PUBLIC_URL}/Token`}>
                    <Token />
                  </Route>
                  {/* <Route exact path={`${process.env.PUBLIC_URL}/UsersPermissions`}>
                    <UserPermissions subscriptionGuid={data.subscription.GUID} />
                  </Route> */}
                  <Route path={`${process.env.PUBLIC_URL}/`}>
                    <Home
                      subscriptionGuid={data.subscription.GUID}
                      quantum={data.subscription.SyncQuantum}
                      isAdmin={data.subscription.IsAdmin}
                    />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubscriptionSelectinModel
        subscriptionGuidCallback={subscriptionCallback}
      />
    </Router>
  );
};

export default App;
