import React, { useState, useEffect } from "react";
import PivotGrid, { Export, Scrolling, FieldChooser, PivotGridTexts } from "devextreme-react/pivot-grid";
import {
  BaseUrl,
  errorAlert,
  UserLogOut
} from "../../Components/GlobalFunctions";
import { Link } from "react-router-dom";

let providersList = [];
const Provider = props => {
  const [data, setData] = useState({
    dataSource: {
      store: providersList,
      fields: [
        {
          caption: "Label",
          dataField: "Label",
          width: 150,
          area: "row"
        },
        {
          caption: "PosLabel",
          dataField: "PosLabel",
          area: "column"
        },
        {
          caption: "WarehouseLabel",
          dataField: "WarehouseLabel",
          area: "column"
        },
        {
          caption: "Solde",
          dataField: "Balance",
          dataType: "number",
          summaryType: "sum",
          format: "#,###.## DZD",
          area: "data"
        },
        {
          caption: "Solde Max",
          dataField: "MaxBalance",
          dataType: "number",
          summaryType: "sum",
          format: "#,###.## DZD",
          area: "data"
        }
      ]
    },
    hasPermission: true
  });

  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    document.body.classList.add("m-page--loading");
  } else {
    document.body.classList.remove("m-page--loading");
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      let response = await fetch(`${BaseUrl()}/ThirdPartyProviderAdvanced`, {
        method: "get",
        headers: {
          "Subscription-Guid": props.subscriptionGuid,
          Authorization: localStorage.token
        }
      });

      if (response.status === 200) {
        providersList = await response.json();

        setData({
          dataSource: {
            store: providersList.Data,
            fields: data.dataSource.fields
          },
          hasPermission: providersList.HasPermission
        });
      } else if (response.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(() => UserLogOut(props.subscriptionGuid));
      } else {
        errorAlert("An error occured");
      }
      setIsLoading(false);
    }
    fetchData();
  }, [props.subscriptionGuid]);

  var  timerId;
  
  var  debounceFunction  =   (func, delay) => {
    // Cancels the setTimeout method execution
    clearTimeout(timerId)
  
    // Executes the func after delay time.
    timerId  =  setTimeout(func, delay)
  }

  const handleChange = event => {
    let keyWord = event.target.value;

    debounceFunction(() => {
      setData({
        dataSource: {
          store: providersList.Data.filter(x =>
            x.Label.toLowerCase().includes(keyWord.toLowerCase())
          ),
          fields: data.dataSource.fields
        },
        hasPermission: providersList.HasPermission
      });
    }, 500)

    
  };

  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Vue Avancé Fournisseur
            </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="m-nav__link m-nav__link--icon"
                >
                  <i className="m-nav__link-icon la la-home"></i>
                </Link>
              </li>
              <li className="m-nav__separator">-</li>
              <li className="m-nav__item">
                <a href="" className="m-nav__link">
                  <span className="m-nav__link-text">
                    Vue Avancé Fournisseur
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="m-content">
        <div className="col-12 pb-2">
          <input
            type="text"
            className="form-control m-input col-12 col-md-4 offset-md-8"
            placeholder="Search..."
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
        {!data.hasPermission && 
            <div className="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show" role="alert">
            <div className="m-alert__icon">
              <i className="flaticon-exclamation-1"></i>
              <span></span>
            </div>
            <div className="m-alert__text">
                <strong>Permissions rejetées!</strong>  Vous n'avez pas les permissions pour consulter cette page.
            </div>		  	
          </div>
          }
          <PivotGrid
            id={"ProviderView"}
            dataSource={data.dataSource}
            allowExpandAll={true}
            showBorders={true}
            showTotalsPrior={"rows"}
            showColumnTotals={true}
            height={620}
          >
            <FieldChooser enabled={true} allowSearch={true} texts={
              {
                columnFields: "Champs de colonne", 
                rowFields: "Champs de ligne",
                allFields: "Tous les champs",
                dataFields: "Champs des données",
                filterFields: "Champs des filters"
              }
            } title={"Sélecteur de champs"} />
            <PivotGridTexts grandTotal={"Total"} />
            <Export enabled={true} fileName={"List Fournisseur"} />
            <Scrolling mode={"virtual"} />
          </PivotGrid>
        </div>
      </div>
    </div>
  );
};

export default Provider;
