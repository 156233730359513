function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

docReady(() => {
    let $ = window.jQuery;
    $(".m-menu__subnav").on("click",function(event){
        $(".m-menu__item.m-menu__item--hover").removeClass("m-menu__item--hover");
      })
})

