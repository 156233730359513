import React, { useState, useEffect } from "react";
import PivotGrid, { Export, Scrolling, FieldChooser, PivotGridTexts } from "devextreme-react/pivot-grid";
import {
  BaseUrl,
  errorAlert,
  UserLogOut
} from "../../Components/GlobalFunctions";
import { Link } from "react-router-dom";

let ProductList = [];
const AdvancedProducts = props => {
  
  const [data, setData] = useState({
    dataSource: {
      store: ProductList,
      fields: []
    },
    hasPermission: true
  });

  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    document.body.classList.add("m-page--loading");
  } else {
    document.body.classList.remove("m-page--loading");
  }

  useEffect(() => {
    
    async function fetchData(){
      // need to compare the props.useBatch to true because of the type of the object is not boolean
      const link = props.useBatch === true ? `${BaseUrl()}/item/advancedBatch` : `${BaseUrl()}/item/advanced`;

      setIsLoading(true);
      let response = await fetch(link, {
        method: "get",
        headers: {
          "Subscription-Guid": props.subscriptionGuid,
          "Authorization": localStorage.token
        }
      });
      setIsLoading(false);
      if (response.status === 200) {
        let result = await response.json();

        ProductList = result.Data;
        if(props.useBatch === true){
          setData({
            dataSource: {
              store: ProductList,
              fields: [
                {
                  caption: "Item",
                  dataField: "Item",
                  width: 150,
                  area: "row"
                },
                {
                  caption: "POS",
                  dataField: "POS",
                  area: "column"
                },
                {
                  caption: "Warehouse",
                  dataField: "Warehouse",
                  area: "column"
                },
                {
                  caption: "Quantity",
                  dataField: "Quantity",
                  dataType: "number",
                  summaryType: "sum",
                  area: "data",
                  format: "fixedPoint"
                },
                {
                  caption: "Batch",
                  dataField: "Batch",
                  area: "row"
                }
              ]
            },
            hasPermission: result.HasPermission
          });
        }
        else{
          setData({
            dataSource: {
              store: ProductList,
              fields: [
                {
                  caption: "Item",
                  dataField: "Item",
                  width: 150,
                  area: "row"
                },
                {
                  caption: "POS",
                  dataField: "POS",
                  area: "column"
                },
                {
                  caption: "Warehouse",
                  dataField: "Warehouse",
                  area: "column"
                },
                {
                  caption: "Quantity",
                  dataField: "Quantity",
                  dataType: "number",
                  summaryType: "sum",
                  area: "data",
                  format: "fixedPoint"
                }
              ]
            },
            hasPermission: result.HasPermission
          });
        }
        
      } else if (response.status === 401) {
        errorAlert("Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion").then(() => UserLogOut(props.subscriptionGuid));
      } else {
        errorAlert("An error occured");
      }
    }
    
    fetchData();
  }, [props.subscriptionGuid]);

  var  timerId;
  
  var  debounceFunction  =   (func, delay) => {
    // Cancels the setTimeout method execution
    clearTimeout(timerId)
  
    // Executes the func after delay time.
    timerId  =  setTimeout(func, delay)
  }

  const handleChange = event => {
    let keyWord = event.target.value;

    debounceFunction(() => {
      setData({
        dataSource: {
          store: ProductList.filter(x =>
            x.Item.toLowerCase().includes(keyWord.trim().toLowerCase())
          ),
          fields: data.dataSource.fields
        },
        hasPermission: data.hasPermission
      });
    }, 500)
    
  };

  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Vue Avancé Produits
            </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="m-nav__link m-nav__link--icon"
                >
                  <i className="m-nav__link-icon la la-home"></i>
                </Link>
              </li>
              <li className="m-nav__separator">-</li>
              <li className="m-nav__item">
                <a href="" className="m-nav__link">
                  <span className="m-nav__link-text">Vue Avancé Produit</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="m-content">
        <div className="col-12 pb-2">
          {!data.hasPermission && (
            <div
              className="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <div className="m-alert__icon">
                <i className="flaticon-exclamation-1"></i>
                <span></span>
              </div>
              <div className="m-alert__text">
                <strong>Permissions rejetées!</strong> Vous n'avez pas les
                permissions pour consulter cette page.
              </div>
            </div>
          )}
          <input
            type="text"
            className="form-control m-input col-12 col-md-4 offset-md-8"
            placeholder="Search..."
            onChange={handleChange}
          />
        </div>
        <div className="col-12">
          <PivotGrid
            id={"ProductView"}
            dataSource={data.dataSource}
            allowSorting={true}
            allowFiltering={true}
            showBorders={true}
            showTotalsPrior={"rows"}
            showColumnTotals={false}
            allowExpandAll={true}
            height={620}
          >
            <FieldChooser enabled={true} allowSearch={true} texts={
              {
                columnFields: "Champs de colonne", 
                rowFields: "Champs de ligne",
                allFields: "Tous les champs",
                dataFields: "Champs des données",
                filterFields: "Champs des filters"
              }
            } title={"Sélecteur de champs"} />
            <PivotGridTexts grandTotal={"Total"} />
            <Export enabled={true} fileName={"List Produits"} />
            <Scrolling mode={"virtual"} />
          </PivotGrid>
        </div>
      </div>
    </div>
  );
};
export default AdvancedProducts;
