import React, { useState, useEffect } from "react";
import { UserLogOut, BaseUrl, errorAlert } from '../Components/GlobalFunctions';
import { Redirect } from "react-router-dom";
import { formatDateTimeFr } from '../Utils/DateUtils'

const Home = props => {
    const [data, setData] = useState([
    {
      POSCode: "N/A",
      Label: "N/A",
      LicenseID: "N/A",
      LastUpdateDate: new Date()
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  if(isLoading){
    document.body.classList.add('m-page--loading')
  }
  else{
    document.body.classList.remove('m-page--loading')
  }

  useEffect(() => {
    if(props.subscriptionGuid != "N/A"){
        async function fetchData() {
            setIsLoading(true);
            let response = await fetch(`${BaseUrl()}/posesSync`, {
              method: "get",
              headers: {
                Authorization: localStorage.token,
                "Subscription-Guid": props.subscriptionGuid
              }
            });
            
            setIsLoading(false);
            if(response.status === 200){
              let result = await response.json();
              setData(result);
            }
            else if(response.status === 401){
              errorAlert("Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion").then(() => UserLogOut(props.subscriptionGuid))
            }
            else{
              errorAlert('An error occured');
            }
            
          }
          fetchData();
    }
  }, [props.subscriptionGuid]);


  let POSStatus;
  if(data.length > 0){
    const isSynced = (syncDate) => {
      const dateYear = syncDate.getFullYear();
      const currentDateYear = new Date().getFullYear();
      if(dateYear != currentDateYear)
        return <>Pas encore synchronisé</>;
      else
        return <> {formatDateTimeFr(syncDate)} </>;
    }
    POSStatus = data.map((item, index) => {

      let date = new Date(item.LastUpdateDate);
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      let lastUpdate = new Date(date.getTime() + userTimezoneOffset);

      return (
        <div className="m-widget12__item" key={index}>
          <span className="m-widget12__text1">
            {item.POSCode}
            <br />
            <span>{item.Label}</span>
          </span>
          <span className="m-widget12__text2" style={{textAlign: 'right'}}>
            Dernière Synchronization
            <br />
            <span className={ lastUpdate  < ( new Date(new Date().getTime() + userTimezoneOffset - (props.quantum * 60 * 1000)) ) ? "m--font-danger" : "m--font-success" }>
             {isSynced(lastUpdate)}
            </span>
          </span>
        </div>
      )
    });
  }
  else{
    if(props.isAdmin)
      POSStatus = <Redirect to={`/PosConfiguration`} />;
    else
      POSStatus = <p> Les points de vente ne sont pas configurés, contactez votre administrateur </p>;
  }

  

  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Accueil
            </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <a href="#" className="m-nav__link m-nav__link--icon">
                  <i className="m-nav__link-icon la la-home"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <div className="m-portlet m-portlet--full-height ">
          <div className="m-portlet__head">
            <div className="m-portlet__head-caption">
              <div className="m-portlet__head-title">
                <h3 className="m-portlet__head-text">
                  État des Points de Ventes
                </h3>
              </div>
            </div>
          </div>
          <div className="m-portlet__body">
            <div className="m-widget12">
              {POSStatus}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
