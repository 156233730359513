import React, { useState, useEffect } from "react";
import { BaseUrl, errorAlert, UserLogOut } from "../GlobalFunctions";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { DropDownButton } from "devextreme-react";

const $ = window.jQuery;

const SubscriptionSelectinModel = props => {
    const [customers, setCustomers] = useState([
        {
            Id: 0,
            Code: "N/A",
            CompanyName: "N/A",
            Subscriptions: [
                {
                    GUID: "N/A",
                    Label: "N/A",
                    EndDate: new Date(),
                    UseBatch: false,
                    SyncQuantum: 30,
                    IsAdmin: false
                }
            ],
            PharmaceuticalField: false,

        }
    ]);
    const [subscriptions, setSubscriptions] = useState([
        {
            GUID: "N/A",
            Label: "N/A",
            EndDate: new Date(),
            UseBatch: false,
            SyncQuantum: 30,
            IsAdmin: false
        }
    ]);
    const [customerInfo, setCustomerInfo] = useState({
        customerName: "Entreprise",
        subscription: {
            GUID: "N/A",
            Label: "Abonnement",
            EndDate: new Date(),
            UseBatch: false,
            SyncQuantum: 30,
            IsAdmin: false,
        },
        isPharmaceutical: false
    });
    const [isLoading, setIsLoading] = useState(false);
    if (isLoading) {
        document.body.classList.add("m-page--loading");
    } else {
        document.body.classList.remove("m-page--loading");
    }

    if (localStorage.currentCustomer === undefined) {
        $("#m_modal_5").modal("show");
    }
    function SelectCustomer(index) {

        setSubscriptions(customers[index].Subscriptions);
        setCustomerInfo({
            customerName: customers[index].CompanyName,
            subscription: {
                GUID: "N/A",
                Label: "Subscriptions",
                EndDate: new Date(),
                UseBatch: false,
                SyncQuantum: 30,
                isAdmin: false,
            },
            isPharmaceutical: customers[index].PharmaceuticalField
        });
    }

    function SelectSubscription(index) {
        setCustomerInfo({
            subscription: subscriptions[index],
            customerName: customerInfo.customerName,
            isPharmaceutical: customerInfo.isPharmaceutical
        });
    }

    function Save() {
        if (customerInfo.customerName === "Customers" || customerInfo.subscription.GUID === "N/A")
            return;
        props.subscriptionGuidCallback(customerInfo);
    }


    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            let response = await fetch(`${BaseUrl()}/subscriptions`, {
                headers: {
                    Authorization: localStorage.token
                }
            });
            if (response.status === 200) {
                let result = await response.json();
                setCustomers(result);
            } else if (response.status === 401) {
                errorAlert(
                    "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
                ).then(() => UserLogOut(props.subscriptionGuid));
            } else {
                errorAlert("An error occured");
            }
            setIsLoading(false);
        }

        fetchData();
        if (customers.length == 0)
            UserLogOut();
    }, []); // onMounted

    return <div
        className="modal fade"
        id="m_modal_5"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Liste des Entreprise et des abonnements</h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{display:'flex', flexDirection:'column'}}>
                    <DropdownButton id="dropdown-item-button" title={customerInfo.customerName} >
                        {customers.map((customerItem, customerIndex) => (
                            <Dropdown.Item as="button" key={customerIndex} onClick={() => SelectCustomer(customerIndex)}>{customerItem.CompanyName}</Dropdown.Item>
                        ))}
                    </DropdownButton >

                    <DropdownButton id="dropdown-item-button-2" title={customerInfo.subscription.Label} >
                        {subscriptions.map((subscriptionItem, subscriptionIndex) => (
                            <Dropdown.Item as="button" key={subscriptionIndex} onClick={() => SelectSubscription(subscriptionIndex)}>{subscriptionItem.Label} {new Date(subscriptionItem.EndDate) < new Date() && <span style={{ color: 'red' }}> (Expiré)</span>}</Dropdown.Item>
                        ))}
                    </DropdownButton >
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Annuler
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={Save}
                        data-dismiss="modal"
                    >Valider</button>
                </div>
            </div>
        </div>
    </div>;
};

export default SubscriptionSelectinModel;
