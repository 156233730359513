import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty, BaseUrl } from "../../Components/GlobalFunctions";
import SimpleViewResource from "./SimpleViewResources";
import DataGrid, {
  Column,
  Paging,
  Pager,
  SearchPanel,
  FilterRow
} from "devextreme-react/data-grid";
import { Link } from "react-router-dom";
import { localUrl } from '../GlobalFunctions';

const pageSizes = [10, 25, 50, 100];

const Products = props => {
  const link = props.isPharmaceutical
    ? `${BaseUrl()}/Pharmaceutical`
    : `${BaseUrl()}/items`;

    // console.log(`link = ${link}`);

  const [productsData, setproductsData] = useState({
    redirect: false,
    productId: 0,
    availableProducts: false
  });

  const onSelectionChanged = ({ selectedRowsData }) => {
    setproductsData({
      redirect: true,
      productId: selectedRowsData[0].Id
    });
  };
  const onFilterChanged = () => {
    setproductsData({
      availableProducts: !productsData.availableProducts
    });
  };
  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Liste des Produits
            </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="m-nav__link m-nav__link--icon"
                >
                  <i className="m-nav__link-icon la la-home"></i>
                </Link>
              </li>
              <li className="m-nav__separator">-</li>
              <li className="m-nav__item">
                <a href="" className="m-nav__link">
                  <span className="m-nav__link-text">Liste des Produits</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="m-content">
        <SimpleViewResource
          subscriptionGuid={props.subscriptionGuid}
          linkGetData={`?availableProducts=${productsData.availableProducts}`}
          link={link}
          render={data => {
            if (!!data && !isEmpty(data)) {
              if (!data.items.HasPermission)
                return (
                  <div
                    className="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <div className="m-alert__icon">
                      <i className="flaticon-exclamation-1"></i>
                      <span></span>
                    </div>
                    <div className="m-alert__text">
                      <strong>Permissions rejetées!</strong> Vous n'avez pas les
                      permissions pour consulter cette page.
                    </div>
                  </div>
                );
              else {
                return (
                  <>
                    <div
                      className="m-accordion m-accordion--default m-accordion--solid m-accordion--section  m-accordion--toggle-arrow"
                      id="m_accordion_7"
                      role="tablist"
                      style={{ margin: "5px" }}
                    >
                      <div className="m-accordion__item">
                        <div
                          className="m-accordion__item-head collapsed"
                          role="tab"
                          id="m_accordion_7_item_1_head"
                          data-toggle="collapse"
                          href="#m_accordion_7_item_1_body"
                          aria-expanded="    false"
                        >
                          <span className="m-accordion__item-icon">
                            <i className="la la-filter"></i>
                          </span>
                          <span className="m-accordion__item-title">
                            Filtres avancés
                          </span>

                          <span className="m-accordion__item-mode"></span>
                        </div>

                        <div
                          className="m-accordion__item-body collapse"
                          id="m_accordion_7_item_1_body"
                          role="tabpanel"
                          aria-labelledby="m_accordion_7_item_1_head"
                          data-parent="#m_accordion_7"
                        >
                          <div className="m-accordion__item-content" style={{paddingTop: "10px"}}>
                            <label
                              className="m-checkbox m-checkbox--state-primary"
                              style={{ marginTop: "10px" }}
                            >
                              <input
                                type="checkbox"
                                defaultChecked={productsData.availableProducts}
                                onChange={onFilterChanged}
                              />
                              Produits disponibles
                              <span></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DataGrid
                      dataSource={data.items.Data}
                      selection={{ mode: "single" }}
                      showBorders={true}
                      hoverStateEnabled={true}
                      onSelectionChanged={onSelectionChanged}
                      keyExpr={"Id"}
                      rowAlternationEnabled={true}
                    >
                      <SearchPanel
                        visible={true}
                        style={{ PaddingTop: "10px", background: "#f2f3f8" }}
                      />
                      <FilterRow visible={true} />
                      <Column dataField={"Code"} caption={"Code"} />
                      <Column dataField={"Reference"} caption={"Référence"}/>
                      <Column dataField={"Label"} caption={"Désignation"}/>
                      {props.isPharmaceutical && <Column dataField={"INN"} />}
                      {props.isPharmaceutical && <Column dataField={"Dose"} />}
                      {props.isPharmaceutical && <Column dataField={"Presentation"} />}                     
                      {props.UseBatch && <Column dataField={"Batches"} caption={"Lot"} />}
                      <Pager
                        allowedPageSizes={pageSizes}
                        showPageSizeSelector={true}
                      />
                      <Paging defaultPageSize={10} />
                    </DataGrid>
                  </>
                );
              }
            }
            return <h1>No data</h1>;
          }}
        />
        {productsData.redirect && (
          <Redirect
            push
            to={{
              pathname: `${localUrl()}ItemDetail/${productsData.productId}`
            }}
          />
        )}
      </div>
    </div>
  );
};
export default Products;
