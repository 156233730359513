import React from "react";
import { localUrl, UserLogOut } from './GlobalFunctions';

const Token = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get('token');

  if(!token){
    UserLogOut();
    return <></>;
  }

  localStorage.setItem("token", `Bearer ${token}`);
  // required to reload the app
  window.location.replace(localUrl());

  return  <></>;
};
export default Token;