import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataGrid, { Column,  Pager,  Editing,  Paging,  Lookup,  FormItem } from "devextreme-react/data-grid";
import { UserLogOut,  BaseUrl,  errorAlert,  successAlert } from "../GlobalFunctions";


const PosConfiguration = props => {
  
  const [isLoading, setIsLoading] = useState(false);  
  const [data, setData] = useState({
    Poses: [
      {
        PosId: 0,
        POSCode: "N/A",
        Label: "N/A",
        LicenseID: "N/A",
        CollectionID: "N/A",
        Platform: 0
      }
    ],
    Licenses: [
      {
        Label: "N/a",
        GUID: "N/A"
      }
    ],
    Collections: [
      {
        GUID: "N/A",
        Label: "N/A",
        licenseID: "N/A"
      }
    ],
    PosNumber: 0
  });

  async function fetchData() {
    setIsLoading(true);
    let request = fetch(`${BaseUrl()}/poses`, {
      headers: {
        Authorization: localStorage.token,
        "Subscription-Guid": props.subscriptionGuid
      }
    });
    await request
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 401) {
          errorAlert(
            "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
          ).then(UserLogOut);
        } else {
          errorAlert("An error occured");
        }
      })
      .then(res => setData(res));
    setIsLoading(false);
  }

  useEffect(() => {    
    fetchData();
  }, []);

  if (isLoading) {
    document.body.classList.add("m-page--loading");
  } else {
    document.body.classList.remove("m-page--loading");
  }

  const getColletions = options => {
    return {
      store: data.Collections,
      filter: options.data ? ["licenseID", "=", options.data.LicenseID] : null
    };
  };

  const getLicences = options => {
    // if (options.data != null)
    //   return {
    //     store: data.Licenses.filter(
    //       x => data.Poses.findIndex(y => y.LicenseID === x.GUID) === -1 || x.GUID === options.data.LicenseID
    //     )
    //   };
    // return {
    //   store: data.Licenses.filter(
    //     x => data.Poses.findIndex(y => y.LicenseID === x.GUID) === -1 //|| x.GUID == options.data.LicenseID
    //   )
    // };

    return {
      store: data.Licenses
    }
  };

  const getPlatforms = options =>{
      return {
          store: [{ Label: 'Works', value: 0 }, { Label: 'Zorg / AfterWave', value: 1}]
      } 
  }

  const setLicenseValue = (rowData, value) => {
    rowData.CollectionID = null;
    rowData.LicenseID = value;
  };

  const sendRequest = (url, requestData) => {
    setIsLoading(true);
    fetch(url, {
      method: "post",
      headers: {
        Authorization: localStorage.token,
        "Subscription-Guid": props.subscriptionGuid,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestData)
    }).then(async res => {
      setIsLoading(false);
      if (res.status === 200) {
        successAlert("Mises à jour réussit");
      } else if (res.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(UserLogOut);
      } else if (res.status === 400){
        // BadRequest
        const message = (await res.json()).Message;
        errorAlert(`An error occured: ${message}`);
      }
      else {        
        errorAlert(`An error occured`);
      }
    });
  };

  const onInitNewRow = options => {
    if (data.Poses.length >= data.PosNumber) {
      alert("vous avez atteint le nombre maximum des points de vente!");
      setTimeout(_ => options.component.cancelEditData(), 0);
    }
  };

  const onRowInserted = options => {
    // console.log(options)
    sendRequest(`${BaseUrl()}/pos`, options.data);
    fetchData();
  };

  const onRowUpdated = options => {
    sendRequest(`${BaseUrl()}/UpdatePOS`, options.data);
  };

  const onRowRemoved = options => {
    //console.log(options);
    sendRequest(`${BaseUrl()}/DeletePOS`, options.data);
  };

  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Configuration des POS
            </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="m-nav__link m-nav__link--icon"
                >
                  <i className="m-nav__link-icon la la-home"></i>
                </Link>
              </li>
              <li className="m-nav__separator">-</li>
              <li className="m-nav__item">
                <a href="" className="m-nav__link">
                  <span className="m-nav__link-text">
                    Configuration des POS
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="m-content">
        <div className="row">
          <div className="col-xl-12" style={{ textAlign: "left" }}>
            <div className="m-portlet m-portlet--full-height">
              <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                  <div className="m-portlet__head-title">
                    <h3 className="m-portlet__head-text">
                      Configuration des POS (Max = {data.PosNumber})
                    </h3>
                  </div>
                </div>
              </div>

              <div className="m-portlet__body">
                <div className="tab-content">
                  <div className="tab-pane active" id="m_widget11_tab1_content">
                    <div
                      className="m-widget11"
                      data-height="300"
                      data-mobile-height="300"
                    >
                      <DataGrid
                        dataSource={data.Poses}
                        showBorders={true}
                        keyExpr={"PosId"}
                        allowColumnResizing={true}
                        onInitNewRow={onInitNewRow}
                        onRowInserted={onRowInserted}
                        onRowUpdated={onRowUpdated}
                        onRowRemoved={onRowRemoved}
                      >
                        <Paging enabled={false} />
                        <Editing
                          mode={"form"}
                          allowUpdating={true}
                          allowDeleting={true}
                          allowAdding={true}
                        />
                        <Column dataField={"Label"} caption={"Label"} />
                        <Column dataField={"POSCode"} caption={"Code"} />
                        <Column dataField={"LicenseLabel"} caption={"License"}>
                          <FormItem visible={false} />
                        </Column>
                        <Column
                          dataField={"CollectionLabel"}
                          caption={"Collection"}
                          allowEditing={false}
                        >
                          <FormItem visible={false} />
                        </Column>
                        <Column
                          dataField={"LicenseID"}
                          caption={"License"}
                          visible={false}
                          setCellValue={setLicenseValue}
                        >
                          <Lookup
                            dataSource={getLicences}
                            valueExpr="GUID"
                            displayExpr="Label"
                          />
                        </Column>
                        <Column
                          dataField={"CollectionID"}
                          caption={"Collection"}
                          visible={false}
                        >
                          <Lookup
                            dataSource={getColletions}
                            valueExpr="GUID"
                            displayExpr="Label"
                          />
                        </Column>

                        <Column
                          dataField={"Platform"}
                          caption={"Platform"}
                          visible={false}
                        >
                          <Lookup
                            dataSource={getPlatforms}
                            valueExpr="value"
                            displayExpr="Label"
                          />
                        </Column>

                        <Pager
                          showPageSizeSelector={true}
                          allowedPageSizes={[10, 20, 30, 50]}
                        />
                      </DataGrid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosConfiguration;
