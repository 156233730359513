import React, { useState, useEffect } from "react";
import {
  UserLogOut,
  BaseUrl,
  errorAlert
} from "../../Components/GlobalFunctions";

import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  SearchPanel,
  FilterRow
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { Link } from "react-router-dom";

const pageSizes = [10, 25, 50, 100];

const ConnectionLogHistory = props => {
  const [data, setData] = useState({
    dataSource: {}
  });

  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    document.body.classList.add("m-page--loading");
  } else {
    document.body.classList.remove("m-page--loading");
  }

  useEffect(() => {
    setData({
      dataSource: {
        store: new CustomStore({
          load: async loadOptions => {
            let link = `${BaseUrl()}/UserLogHistory`;
            setIsLoading(true);
            let response = await fetch(`${link}`, {
              method: "get",
              headers: {
                "Subscription-Guid": props.subscriptionGuid,
                "Authorization": localStorage.token
              }
            });
            setIsLoading(false);
            switch (response.status) {
              case 200: {
                let jsonResponse = await response.json();
                return {
                  data: jsonResponse
                };
              }
              case 401: {
                errorAlert(
                  "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
                ).then(() => UserLogOut(props.subscriptionGuid));

                break;
              }
              default: {
                errorAlert("An error occured");
              }
            }
            // .then(response => {
            //   if (response.status === 200) {
            //     setIsLoading(false);
            //     return response.json();
            //   } else if (response.status === 401) {
            //     errorAlert(
            //       "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
            //     ).then(() => UserLogOut(props.subscriptionGuid));
            //   } else {
            //     setIsLoading(false);
            //     errorAlert("An error occured");
            //   }
            // })
            // .then(response => {
            //   return {
            //     data: response
            //   };
            // });
          }
        })
      }
    });
  }, [props.subscriptionGuid]);

  const header = (
    <div className="m-subheader ">
      <div className="d-flex align-items-center">
        <div className="mr-auto">
          <h3 className="m-subheader__title m-subheader__title--separator">
            Historique des connexions
          </h3>
          <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li className="m-nav__item m-nav__item--home">
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                className="m-nav__link m-nav__link--icon"
              >
                <i className="m-nav__link-icon la la-home"></i>
              </Link>
            </li>
            <li className="m-nav__separator">-</li>
            <li className="m-nav__item">
              <a href="" className="m-nav__link">
                <span className="m-nav__link-text">
                  Historique des connexions
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
  const content = (
    <div className="m-content">
      <DataGrid
        dataSource={data.dataSource}
        showBorders={true}
        rowAlternationEnabled={true}
      >
        <SearchPanel
          visible={true}
          style={{ PaddingTop: "10px", background: "#f2f3f8" }}
        />
        <FilterRow visible={true} />
        <Column dataField={"Username"} caption="Utilisateur" />
        <Column dataField={"Email"} caption="Email" />
        <Column
          dataField={"LoginDate"}
          caption="Date de connexion"
          dataType={"datetime"}
          format={"dd/MM/yyyy, HH:mm"}
          alignment={"right"}
        />
        <Column
          dataField={"LogoutDate"}
          caption="Date de déconnexion"
          dataType={"datetime"}
          format={"dd/MM/yyyy, HH:mm"}
          alignment={"right"}
        />
        <Column dataField={"IpAddress"} caption="IP" />

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </div>
  );
  // Add access check
  return (
    <div>
      {header} {content}
    </div>
  );
};

export default ConnectionLogHistory;
