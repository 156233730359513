import React from "react";
import { Link } from "react-router-dom";

const Links = props => {
  
  return (
    <div
      className="m-stack__item m-stack__item--middle m-stack__item--left m-header-head"
      id="m_header_nav"
    >
      <div className="m-stack m-stack--ver m-stack--desktop">
        <div className="m-stack__item m-stack__item--fluid">
          <button
            className="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark "
            id="m_aside_header_menu_mobile_close_btn"
          >
            <i className="la la-close"></i>
          </button>
          <div
            id="m_header_menu"
            className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark "
          >
            <ul className="m-menu__nav  m-menu__nav--submenu-arrow ">
              <li
                className="m-menu__item  m-menu__item--submenu m-menu__item--rel"
                m-menu-submenu-toggle="click"
                aria-haspopup="true"
              >
                <a href="#" className="m-menu__link m-menu__toggle">
                  <span className="m-menu__item-here"></span>
                  <i className="m-menu__link-icon flaticon-imac"></i>
                  <span className="m-menu__link-text">Vue Simple</span>
                  <i className="m-menu__hor-arrow la la-angle-down"></i>
                  <i className="m-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="m-menu__submenu m-menu__submenu--classNameic m-menu__submenu--left">
                  <span className="m-menu__arrow m-menu__arrow--adjust"></span>
  
                  <ul className="m-menu__subnav">
                    <li
                      className="m-menu__item  m-menu__item--submenu"
                      m-menu-submenu-toggle="hover"
                      m-menu-link-redirect="1"
                      aria-haspopup="true"
                    >
                      <Link
                        to={`${process.env.PUBLIC_URL}/Products`}
                        className="m-menu__link"
                      >
                        <i className="m-menu__link-icon flaticon-layers"></i>
                        <span className="m-menu__link-text">Produits</span>
                      </Link>
                    </li>
                    <li
                      className="m-menu__item  m-menu__item--submenu"
                      m-menu-submenu-toggle="hover"
                      m-menu-link-redirect="1"
                      aria-haspopup="true"
                    >
                      <Link
                        to={`${process.env.PUBLIC_URL}/Clients`}
                        className="m-menu__link"
                      >
                        <i className="m-menu__link-icon flaticon-users"></i>
                        <span className="m-menu__link-text">Clients</span>
                      </Link>
                    </li>
                    <li
                      className="m-menu__item  m-menu__item--submenu"
                      m-menu-submenu-toggle="hover"
                      m-menu-link-redirect="1"
                      aria-haspopup="true"
                    >
                      <Link
                        to={`${process.env.PUBLIC_URL}/Providers`}
                        className="m-menu__link"
                      >
                        <i className="m-menu__link-icon flaticon-avatar"></i>
                        <span className="m-menu__link-text">Fournisseurs</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="m-menu__item  m-menu__item--submenu m-menu__item--rel"
                m-menu-submenu-toggle="click"
                aria-haspopup="true"
              >
                <a href="#" className="m-menu__link m-menu__toggle">
                  <span className="m-menu__item-here"></span>
                  <i className="m-menu__link-icon flaticon-analytics"></i>
                  <span className="m-menu__link-text">Vue Avancé</span>
                  <i className="m-menu__hor-arrow la la-angle-down"></i>
                  <i className="m-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="m-menu__submenu m-menu__submenu--classNameic m-menu__submenu--left">
                  <span className="m-menu__arrow m-menu__arrow--adjust"></span>
                  <ul className="m-menu__subnav">
                    <li
                      className="m-menu__item  m-menu__item--submenu"
                      m-menu-submenu-toggle="hover"
                      m-menu-link-redirect="1"
                      aria-haspopup="true"
                    >
                      <Link
                        to={`${process.env.PUBLIC_URL}/ProductAdvanced`}
                        className="m-menu__link"
                      >
                        <i className="m-menu__link-icon flaticon-layers"></i>
                        <span className="m-menu__link-text">Produits</span>
                      </Link>
                    </li>
                    <li
                      className="m-menu__item  m-menu__item--submenu"
                      m-menu-submenu-toggle="hover"
                      m-menu-link-redirect="1"
                      aria-haspopup="true"
                    >
                      <Link
                        to={`${process.env.PUBLIC_URL}/ClientAdvanced`}
                        className="m-menu__link"
                      >
                        <i className="m-menu__link-icon flaticon-users"></i>
                        <span className="m-menu__link-text">Clients</span>
                      </Link>
                    </li>
                    <li
                      className="m-menu__item  m-menu__item--submenu"
                      m-menu-submenu-toggle="hover"
                      m-menu-link-redirect="1"
                      aria-haspopup="true"
                    >
                      <Link
                        to={`${process.env.PUBLIC_URL}/AdvancedProvider`}
                        className="m-menu__link"
                      >
                        <i className="m-menu__link-icon flaticon-avatar"></i>
                        <span className="m-menu__link-text">Fournisseurs</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {props.isAdmin  && (
                <li
                  className="m-menu__item  m-menu__item--submenu m-menu__item--rel"
                  m-menu-submenu-toggle="click"
                  aria-haspopup="true"
                >
                  <a href="#" className="m-menu__link m-menu__toggle">
                    <span className="m-menu__item-here"></span>
                    <i className="m-menu__link-icon flaticon-settings-1"></i>
                    <span className="m-menu__link-text">Administration</span>
                    <i className="m-menu__hor-arrow la la-angle-down"></i>
                    <i className="m-menu__ver-arrow la la-angle-right"></i>
                  </a>
                  <div className="m-menu__submenu m-menu__submenu--classNameic m-menu__submenu--left">
                    <span className="m-menu__arrow m-menu__arrow--adjust"></span>
                    <ul className="m-menu__subnav">
                      <li
                        className="m-menu__item  m-menu__item--submenu"
                        m-menu-submenu-toggle="hover"
                        m-menu-link-redirect="1"
                        aria-haspopup="true"
                      >
                        <Link
                          to={`${process.env.PUBLIC_URL}/ConnectionsLogHistory`}
                          className="m-menu__link"
                        >
                          <i className="m-menu__link-icon flaticon-interface-2"></i>
                          <span className="m-menu__link-text">
                            Historique des connexions
                          </span>
                        </Link>
                      </li>
                      <li
                        className="m-menu__item  m-menu__item--submenu"
                        m-menu-submenu-toggle="hover"
                        m-menu-link-redirect="1"
                        aria-haspopup="true"
                      >
                        <Link
                          to={`${process.env.PUBLIC_URL}/ResetDatabase`}
                          className="m-menu__link"
                        >
                          <i className="m-menu__link-icon flaticon-refresh"></i>
                          <span className="m-menu__link-text">
                            Renitialisation de la base
                          </span>
                        </Link>
                      </li>
                      <li
                        className="m-menu__item  m-menu__item--submenu"
                        m-menu-submenu-toggle="hover"
                        m-menu-link-redirect="1"
                        aria-haspopup="true"
                      >
                        <Link
                          to={`${process.env.PUBLIC_URL}/PosConfiguration`}
                          className="m-menu__link"
                        >
                          <i className="m-menu__link-icon flaticon-settings"></i>
                          <span className="m-menu__link-text">
                            Configuration des POS
                          </span>
                        </Link>
                      </li>
                      <li
                        className="m-menu__item  m-menu__item--submenu"
                        m-menu-submenu-toggle="hover"
                        m-menu-link-redirect="1"
                        aria-haspopup="true"
                      >
                        <Link
                          to={`${process.env.PUBLIC_URL}/WarehouseConfiguration`}
                          className="m-menu__link"
                        >
                          <i className="m-menu__link-icon flaticon-settings"></i>
                          <span className="m-menu__link-text">
                            Configuration des Dépots
                          </span>
                        </Link>
                      </li>
                      {/* <li
                        className="m-menu__item  m-menu__item--submenu"
                        m-menu-submenu-toggle="hover"
                        m-menu-link-redirect="1"
                        aria-haspopup="true"
                      >
                        <Link
                          to={`${process.env.PUBLIC_URL}/UsersPermissions`}
                          className="m-menu__link"
                        >
                          <i className="m-menu__link-icon 	flaticon-lock-1"></i>
                          <span className="m-menu__link-text">
                            Permissions des Utilisateurs
                          </span>
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </li>
              )}
            </ul>
                
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Links;
