import React, { useState, useEffect } from "react";
import {
  UserLogOut,
  errorAlert
} from "../../Components/GlobalFunctions";

const SimpleViewResources = props => {
    
  let link = props.link;
  if(props.linkGetData)
    link = link + props.linkGetData;
  const [data, setData] = useState({
    isLoading: false,
    payload: {}
  });

  useEffect(() => {
    async function fetchData() {
      setData({ isLoading: true });
      let response = await fetch(link, {
        method: "get",
        headers: {
          "Subscription-Guid": props.subscriptionGuid,
          Authorization: localStorage.token
        }
      });
      setData({ isLoading: false });
      if (response.status === 200) {
        setData({ payload: await response.json()});
      } else if (response.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(() => UserLogOut(this.props.subscriptionGuid));
      } else {
        errorAlert("An error occured");
      }
    }

    fetchData();
  }, [props.subscriptionGuid, link]);

  if (data.isLoading) {
    document.body.classList.add("m-page--loading");
  } else {
    document.body.classList.remove("m-page--loading");
  }
  return props.render(data.payload);
};

export default SimpleViewResources;
