import React, { useState } from "react";
import Swal from "sweetalert2";
import { UserLogOut, BaseUrl, successAlert } from "../../Components/GlobalFunctions";
import { Link } from "react-router-dom";

const errorAlert = text => {
  return Swal.fire({
    title: "Error!",
    text: text,
    type: "error",
    confirmButtonText: "OK"
  });
};
const ResetDatabase = props => {

  const [isLoading, setIsLoading] = useState(false);
  if(isLoading){
    document.body.classList.add('m-page--loading')
  }
  else{
    document.body.classList.remove('m-page--loading')
  }


  const resetClickEvent = () => {
    setIsLoading(true);
    fetch(`${BaseUrl()}/admin/ResetDatabase`, {
      method: "get",
      headers: {
        "Subscription-Guid": props.subscriptionGuid,
        Authorization: localStorage.token
      }
    }).then(response => {
      if(response.status === 200){
        successAlert("La réinitialisation de la base est terminé avec succès");
      }
      if (response.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(() => UserLogOut(props.subscriptionGuid));
      } 
    });
    setIsLoading(false);
  };

  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Renitialisation de la base
            </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="m-nav__link m-nav__link--icon"
                >
                  <i className="m-nav__link-icon la la-home"></i>
                </Link>
              </li>
              <li className="m-nav__separator">-</li>
              <li className="m-nav__item">
                <a href="" className="m-nav__link">
                  <span className="m-nav__link-text">
                    Renitialisation de la base
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="m-portlet m--bg-accent m-portlet--bordered-semi m-portlet--skin-dark m-portlet--full-height ">
            <div className="m-portlet__head">
              <div className="m-portlet__head-caption">
                <div className="m-portlet__head-title">
                  <h3 className="m-portlet__head-text">Avertissement</h3>
                </div>
              </div>
            </div>
            <div className="m-portlet__body">
              <div className="m-widget7 m-widget7--skin-dark">
                <div className="m-widget7__desc">
                  Lors de la réinitialisation de la base de données, tous les enregistrements seront effacés. la prochaine fois que vous retrouverez les données, sera après la synchronisation, le temps de la synchronisation est déterminé dans l'extension client, pour plus d'informations contactez votre admin :)
                </div>
                <br />
                <br />
                <br />
                <div className="m-widget7__button">
                  <a
                    className="m-btn m-btn--pill btn btn-danger"
                    data-toggle="modal"
                    data-target="#m_modal_7"
                    href="#"
                    role="button"
                  >
                    Réinitialiser la base de données
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="m_modal_7"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Réinitialiser la base de données
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Êtes-vous sûr de réinitialiser la base de données?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={resetClickEvent}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetDatabase;
