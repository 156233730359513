import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserLogOut, BaseUrl, errorAlert } from "../GlobalFunctions";

const WarehouseConfiguration = props => {
  const [data, setData] = useState({
    Data: [
      {
        POSCode: "N/A",
        POSLabel: "N/A",
        Warehouses: [
          {
            Code: "N/A",
            Label: "N/A",
            IsComputed: false,
            Updated: false
          }
        ]
      }
    ],
    hasPermission: false
  });

  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    document.body.classList.add("m-page--loading");
  } else {
    document.body.classList.remove("m-page--loading");
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      let response = await fetch(`${BaseUrl()}/warehouses`, {
        method: "get",
        headers: {
          "Subscription-Guid": props.subscriptionGuid,
          Authorization: localStorage.token
        }
      });
      if (response.status === 200) {
        let result = await response.json();
        setData({
          Data: result.Data,
          hasPermission: result.HasPermission
        });
      } else if (response.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(() => UserLogOut(props.subscriptionGuid));
      } else {
        errorAlert("An error occured");
      }
      setIsLoading(false);
    }
    fetchData();
  }, [props.subscriptionGuid]);

  const sendData = async () => {
    async function fetchData() {

      let response = await fetch(`${BaseUrl()}/warehouses`, {
        method: "post",
        headers: {
          "Subscription-Guid": props.subscriptionGuid,
          Authorization: localStorage.token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data.Data)
      });
      if (response.status === 200) {
        let result = await response.json();
        setData({
          Data: result.Data,
          hasPermission: result.HasPermission
        });
      } else if (response.status === 401) {
        errorAlert(
          "Vous n'êtes pas authentifié, vous serez redirigé vers la page de connexion"
        ).then(() => UserLogOut(props.subscriptionGuid));
      } else {
        errorAlert("An error occured");
      }
    }

    setIsLoading(true);
    await fetchData();
    setIsLoading(false);
  }

  const updateWarehouse = (index, subIndex) => {
    setData({
      Data: data.Data.map((x, i) => {
        if (index == i) {
          x.Warehouses.map((y, ii) => {
            if (subIndex == ii){
              y.IsComputed = !y.IsComputed;
              y.Updated = !y.Updated;
            }
              
          })
        }
        return x;
      }),
      hasPermission: data.hasPermission
    })
  }

  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title m-subheader__title--separator">
              Configuration des Dépots
            </h3>
            <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li className="m-nav__item m-nav__item--home">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="m-nav__link m-nav__link--icon"
                >
                  <i className="m-nav__link-icon la la-home"></i>
                </Link>
              </li>
              <li className="m-nav__separator">-</li>
              <li className="m-nav__item">
                <a href="" className="m-nav__link">
                  <span className="m-nav__link-text" >
                    Configuration des Dépots
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <a className="btn btn-accent m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air" onClick={sendData} style={{ color: "white " }}>
              <span>
                <i className="la flaticon-paper-plane"></i>
                <span>Sauvgarder</span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="m-content">
        <div className="col-12 pb-2">
          {!data.hasPermission &&
            <div className="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show" role="alert">
              <div className="m-alert__icon">
                <i className="flaticon-exclamation-1"></i>
                <span></span>
              </div>
              <div className="m-alert__text">
                <strong>Permissions rejetées!</strong>  Vous n'avez pas les permissions pour consulter cette page.
            </div>
            </div>
          }
        </div>
        <div className="row">
          {data.hasPermission &&
            data.Data.map((Item, index) => (
              <div className="col-xl-4" style={{ textAlign: "left" }} key={index}>
                <div className="m-portlet m-portlet--full-height">
                  <div className="m-portlet__head">
                    <div className="m-portlet__head-caption">
                      <div className="m-portlet__head-title">
                        <h3 className="m-portlet__head-text">
                          {Item.POSCode} | {Item.POSLabel}
                        </h3>
                      </div>
                    </div>
                    <div className="m-portlet__head-tools">
                      <ul className="m-portlet__nav">

                      </ul>
                    </div>
                  </div>

                  <div className="m-portlet__body">
                    <div className="tab-content">
                      <div className="tab-pane active" id="m_widget11_tab1_content">
                        <div className="m-widget11" data-height="300" data-mobile-height="300">
                          {Item.Warehouses.map((subItem, subIndex) => (
                            <div key={subIndex} style={{fontWeight: subItem.Updated ? 'bold' : ''}} >
                              <label className="m-checkbox m-checkbox--state-primary">
                                <input
                                  type="checkbox"
                                  defaultChecked={subItem.IsComputed}
                                  onChange={() => updateWarehouse(index, subIndex)}
                                />

                                {subItem.Label} | {subItem.Code}
                                <span></span>
                              </label>
                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default WarehouseConfiguration;
