function pad(s) { 
    return (s < 10) ? '0' + s : s; 
}

/**
 * format date to dd/mm/yyyy
 * @param {Date} dateStr 
 */
export function formatDateFr(dateStr) {
    try {
         // https://stackoverflow.com/questions/13459866/javascript-change-date-into-format-of-dd-mm-yyyy

            if(!dateStr) {
                return "";
            }
        
            var d = new Date(dateStr)
            return `${pad(d.getDate())}/${pad(d.getMonth()+1)}/${d.getFullYear()}`
    } catch (error) {
        return dateStr;
    }   
}

/**
 * format date to dd/mm/yyyy HH:MM:SS
 * @param {Date} dateStr 
 */
export function formatDateTimeFr(dateStr) {
    try {
        if(!dateStr) {
            return "";
        }
    
        var d = new Date(dateStr);
        return `${pad(d.getDate())}/${pad(d.getMonth()+1)}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;    
    } catch (error) {
        return dateStr;
    }
}